<template>
  <div id="profile">
    <Loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      loader="dots"
      color="#198b4a"
    ></Loading>
    <WhiteHeader :isApplication="isApplication" :showNavigation="false"></WhiteHeader>
    <ProfileDropdown :isApplication="isApplication" v-if="isLoggedIn" id="profile-dropdown"></ProfileDropdown>
    <DeleteUserModal
      @delete="deleteUser"
      @close="onCloseDeleteModal"
      :class="{
        slide_right: openedDeleteModal,
        slide_left: closedDeleteModal && !openedDeleteModal,
      }"
      id="delete-user-modal"
    ></DeleteUserModal>
    <ChangePhoneModal :isTempUserChange="false"></ChangePhoneModal>
    <div
      id="mask"
      @click="onClickOutside"
      :class="{
        mask: isChangePhoneOpen || openedDeleteModal || isLanguageModalOpen,
      }"
    ></div>
    <div id="body">
      <div id="profile-container">
        <div id="profile-header">
          <h3>{{ labels.profile.myProfile[language] }}</h3>
        </div>
        <div id="profile-data">
          <div id="name-and-email">
            <div id="name-container">
              <h3>{{ labels.profile.name[language] }}</h3>
              <input v-model="name" type="text" />
            </div>
            <div :class="{ marginTop: !showInvalidEmailMessage }" id="email-container">
              <h3>Email</h3>
              <input v-model="email" type="email" />
              <div
                v-if="showInvalidEmailMessage"
                style="margin-left: 10px"
                :class="{ attemptFailed: true, hide: !showInvalidEmailMessage }"
              >
                <h6>{{ labels.errors.invalidEmail[language] }}</h6>
              </div>
            </div>
          </div>
          <div id="phone-container">
            <h3>{{ labels.profile.mobile[language] }}</h3>
            <div id="phone-number">
              <vue-tel-input
                id="phone-input"
                v-on:country-changed="countryChanged"
                :defaultCountry="storedISO2"
                :validCharactersOnly="true"
                :enabledCountryCode="true"
                :disabled="true"
                v-model="phone"
              ></vue-tel-input>
              <font-awesome-icon
                id="change-phone-button"
                icon="pencil-alt"
                @click="initiateChangePhone"
              />
            </div>
          </div>
          <div id="buttons">
            <div id="delete-user-container">
              <Button @click="onDelete" id="delete-user-button">
                {{
                labels.profile.deleteUser[language]
                }}
              </Button>
            </div>
            <div id="save-changes-container">
              <Button
                :loading="updateButtonLoading"
                @click="onSubmit"
                id="save-changes-button"
              >{{ labels.profile.saveChanges[language] }}</Button>
              <h4
                v-if="successfullProfileChanges"
                id="success-message"
              >{{ labels.profile.successfullChanges[language] }}</h4>
              <h4
                v-else-if="successfullProfileChanges === false"
                id="failure-message"
              >{{ profileChangesMessage[language] }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PoweredByFooter></PoweredByFooter>
  </div>
</template>

<script>
import WhiteHeader from "../WhiteHeader";
import DeleteUserModal from "../DeleteUserModal";
import ChangePhoneModal from "../ChangePhoneModal";
import Button from "../Button";
import ProfileDropdown from "../ProfileDropdown.vue"
import PoweredByFooter from "../PoweredByFooter.vue";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import UserApi from "../../api/user";

export default {
  name: "ProfileScreen",
  components: {
    WhiteHeader,
    Loading,
    DeleteUserModal,
    ChangePhoneModal,
    Button,
    ProfileDropdown,
    PoweredByFooter
  },
  created() {
    let routeName = this.$router.currentRoute.name;
    if (routeName === "profile-with-delete") {
      this.$router.push({ name: "profile" });
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    this.userName = this.storedName;
    this.userPhone = String(this.storedPhoneNumber);
    this.userEmail = this.storedEmail;
    this.user = this.storedUser;

    if (this.$router.currentRoute.params.userId != this.user.id) {
      this.$router.push({ name: "landing" });
    } else {
      this.isApplication = this.$route.query.isApplication;
      this.isLoading = false;
      this.setTitle();
    }

  },

  beforeRouteLeave(to, from, next) {
    if (this.deletedUser) {
      this.$store.dispatch("goToDefaultUserDeleteState");
      this.$router.push({ name: "search" });
      next();
    } else if (this.openedDeleteModal) {
      this.openedDeleteModal = false;
      this.closedDeleteModal = true;
      this.$store.dispatch("goToDefaultUserDeleteState");
      this.$router.push({ name: "profile" });
      next(false);
    } else if (this.isLanguageModalOpen) {
      this.$store.dispatch("closeLanguageModal");
      //below is needed as if route is the same as before after next(false)
      //browser autoscrolls to top of page
      window.history.scrollRestoration = "manual";
      next(false);
    } else {
      next();
    }
  },

  computed: {
    storedUser() {
      return this.$store.getters.getUser;
    },

    storedName() {
      return this.$store.getters.getUserName;
    },

    storedEmail() {
      return this.$store.getters.getUserEmail;
    },

    storedPhoneNumber() {
      return this.$store.getters.getUserPhone;
    },

    storedISO2() {
      return this.$store.getters.getUserISO2;
    },

    authToken() {
      return this.$store.getters.getUserAuthToken;
    },

    jwt() {
      return this.$store.getters.getUserJWT;
    },

    name: {
      get() {
        return this.userName;
      },

      set(newUserName) {
        this.userName = newUserName;
      },
    },

    email: {
      get() {
        return this.userEmail;
      },

      set(newEmail) {
        this.userEmail = newEmail;
      },
    },

    phone: {
      get() {
        return this.userPhone;
      },

      set(newPhone) {
        this.userPhone = newPhone;
      },
    },

    isChangePhoneOpen() {
      return this.$store.getters.getSlideChangePhoneIn;
    },

    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },

    successfullProfileChanges() {
      return this.$store.getters.getProfileChangesSuccess;
    },

    profileChangesMessage() {
      return this.$store.getters.getProfileChangesMessage;
    },

    deletedUser() {
      return this.$store.getters.getDeleteUserSuccess;
    },

    deleteUserMessage() {
      return this.$store.getters.getUserDeleteMessage;
    },

    isLanguageModalOpen() {
      return this.$store.getters.isLanguageModalOpen;
    },

    language() {
      return this.$store.getters.getLanguage;
    },

    title() {
      return `${this.labels.titles.profile[this.language]} | Fluter`;
    }
  },
  props: [],
  data() {
    return {
      userEmail: "",
      userPhone: "",
      userName: "",
      showInvalidEmailMessage: false,
      isLoading: true,
      dialCode: "",
      user: {},
      openedDeleteModal: false,
      closedDeleteModal: false,
      updateButtonLoading: false,
      isApplication: false,
    };
  },
  methods: {
    countryChanged: function (newCountry) {
      this.dialCode = newCountry.dialCode;
    },

    onCloseDeleteModal: function () {
      this.$store.dispatch("goToDefaultUserDeleteState");
      this.onClickOutside();
    },

    onDelete: function () {
      this.$router.push({ name: "profile-with-delete" });
      this.openedDeleteModal = true;
    },

    deleteUser: async function () {
      UserApi.delete(this.authToken, this.jwt);
    },

    onSubmit: async function () {

      let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let isValidEmail = emailRegex.test(String(this.userEmail).toLowerCase());

      this.showInvalidEmailMessage = !isValidEmail && this.userEmail && this.userEmail.trim != "";

      if (!this.showInvalidEmailMessage) {
        if (this.userEmail && this.userEmail.trim() == "") {
          this.userEmail = null;
        }
        this.user.id = undefined;
        this.user.name = this.userName;
        this.user.email = this.userEmail;
        this.user.password = "";
        this.user.verified =
          String(this.user.phone.phoneNumber) === String(this.userPhone) &&
          String(this.user.phone.countryCode) === String(this.dialCode) &&
          this.user.verified;
        this.user.phone.phoneNumber = Number(this.userPhone.replace(/\D/g, ""));
        this.user.phone.countryCode = this.dialCode;
        this.updateButtonLoading = true;
        await UserApi.updateV2(this.user, this.authToken, this.jwt);
        this.updateButtonLoading = false;
      }
    },

    initiateChangePhone: function () {
      this.$store.dispatch("shouldSlideChangePhoneIn");
    },

    onClickOutside: function (event) {
      if (this.isChangePhoneOpen && event.target.id == "mask") {
        this.closeChangePhone();
      } else if (this.openedDeleteModal) {
        this.openedDeleteModal = false;
        this.closedDeleteModal = true;
        if (this.deletedUser) {
          this.$router.push({ name: "search" });
        } else {
          this.$router.push({ name: "profile" });
        }
      } else if (this.isLanguageModalOpen) {
        this.$store.dispatch("closeLanguageModal");
      }
    },

    closeChangePhone: function () {
      this.$store.dispatch("shouldSlideChangePhoneOut");

      this.$store.dispatch("shouldNotSlideChangePhoneIn");

      setTimeout(() => {
        this.$store.dispatch("resetChangePhoneState");
        this.$store.dispatch("setChangePhoneMessage", "");

        this.$store.dispatch("resetChangeVerificationState");
        this.$store.dispatch("setChangeVerificationMessage", "");
      }, 850);
    },

    setTitle() {
      try {
        document.title = this.title;
      } catch (error) {
        console.log("Could not set title");
      }
    }
  },
};
</script>

<style scoped>
#profile {
  display: flex;
  flex-direction: column;
  overflow: hidden !important;
  background: #f7f7f7;
}

#profile-dropdown {
  position: absolute;
  top: 69px;
  right: 20px;
  z-index: 201;
}
@media screen and (max-width: 1285px) {
  #profile-dropdown {
    top: 67px;
  }
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 201;
  opacity: 1;
}

#body {
  display: flex;
  flex-direction: col;
  align-items: center;
  justify-content: center;
  background: transparent;
  height: 100%;
  min-height: 80vh;
  width: 100%;
  background: #f7f7f7;
}

#profile-container {
  transform: translateY(-30%);
  width: 45%;
  min-height: 500px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #f7f7f7;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.24), 2px 2px 4px rgba(0, 0, 0, 0.36);
  border-radius: 24px;
}

#profile-header {
  display: flex;
  align-items: center;
  background: white;
  flex: 1;
  /* height: 12%; */
  /* min-height: 12%; */
  padding: 3% 0 3% 0;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.16), 0 2px 2px rgba(0, 0, 0, 0.23);
}

#profile-header > h3 {
  margin: 0;
  margin-left: 5%;
  font-size: 20px;
  font-weight: 600;
}

#profile-data {
  flex: 60;
  /* height: 100%; */
  /* height: calc(600px - 12%); */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* background: coral; */
}

#name-and-email {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  /* height: 25%; */
  /* padding-top: 7%; */
}

#name-container,
#email-container {
  width: 40%;
}

#name-container > h3,
#email-container > h3 {
  margin-bottom: 20px;
  font-size: 18px;
}

#phone-container {
  width: 40%;
  margin-left: 5%;
  /* height: 15%; */
  /* padding-top: 7%; */
}

#phone-container > h3 {
  margin-bottom: 20px;
  font-size: 18px;
}

#phone-number {
  min-width: 260px;
  display: flex;
  align-items: center;
}

#phone-number > h3 {
  margin: 0;
  margin-right: 10px;
  font-size: 18px;
}

#invalid-phone-message > h6 {
  margin: 0;
}

#invalid-phone-message {
  margin-left: 24px;
}

#buttons {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  /* height: 15%; */
  /* margin-top: 5%; */
  /* padding: 0 0 5% 0; */
}

#save-changes-container {
  width: 28%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: flex-start;
  margin-right: 5%;
  /* background: blue; */
}

#save-changes-button {
  border: none;
  width: 100%;
  margin-bottom: 8%;
  color: white;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  background: linear-gradient(62deg, #198b4a, #04da6f);
  /* width: 28%; */
}

#success-message,
#failure-message {
  margin: 0;
  margin-left: 3%;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
}

#success-message {
  color: #198b4a;
}

#failure-message {
  color: #f30;
}

#delete-user-container {
  width: 28%;
  margin-left: 5%;
}

#delete-user-button {
  border: none;
  width: 100%;
  background: transparent;
  color: #f30;
  border: 1px solid #f30;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  transition-duration: 0.35s;
}

#delete-user-button:hover {
  background: #f30;
  color: white;
  /* border: none; */
}

input,
#current-phone-number {
  border: none;
  border-radius: 24px;
  box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.2),
    inset 1px 1px 2px 0 rgba(0, 0, 0, 0.05);
  /* box-shadow: inset 2px 2px 4px 0 rgba(0, 0, 0, 0.2),
    inset 2px 2px 4px 0 rgba(0, 0, 0, 0.2); */
  width: 100%;
  height: 33px;
  padding-left: 10px;
  padding-right: 10px;
  background: #fefefe;
  padding-right: 10px;
  -webkit-appearance: none !important;
}

input:focus {
  outline: none;
}

#phone-input {
  background: #f3f2f2;
  border: none;
  border-radius: 10px;
  box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.2),
    inset -1px -1px 2px 0 rgba(0, 0, 0, 0.05);
  width: 100%;
  margin-right: 20px;
  height: 30px;
  -webkit-appearance: none !important;
}

#change-phone-button {
  color: #198b4a;
  color: #f7f7f7;
  background: #ff8c47;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.23), 2px 2px 4px rgba(0, 0, 0, 0.36);
  border-radius: 100%;
  width: 30px;
  height: 30px;
  padding: 8px;
  cursor: pointer;
}

#phone-input > input {
  border-radius: 10px;
  box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.2),
    inset -1px -1px 2px 0 rgba(0, 0, 0, 0.05);
  padding-right: 10px;
}

#phone-input:focus {
  outline: none;
}

#current-phone-number {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

#current-phone-number > h4 {
  font-size: 16px;
  margin: 0;
  color: gray;
}

.attemptFailed {
  padding-top: 10px;
}

.hide {
  visibility: hidden;
}

.attemptFailed > h6 {
  font-size: 14px;
  color: #f30;
  white-space: pre;
}

.marginTop {
  padding: 0;
}

.passwordMarginTop {
  padding: 0;
}

#delete-user-modal,
#deleted-user-modal,
#not-deleted-user-modal {
  position: absolute;
  top: 10%;
  opacity: 0;
  left: 22.5%;
  transform: translateX(-150%);
}

.slide_right {
  animation: slide-right 0.75s ease-out forwards;
}

@keyframes slide-right {
  from {
    transform: translateX(-150%);
    opacity: 0;
  }

  to {
    transform: translateY(0%);
    opacity: 1;
  }
}

.slide_left {
  animation: slide-left 1s ease-out forwards;
}

@keyframes slide-left {
  from {
    transform: translateY(0%);
    opacity: 1;
  }

  to {
    transform: translateX(-150%);
    opacity: 0;
  }
}

@media screen and (max-width: 1285px) {
  #profile-container {
    transform: translateY(-10%);
    width: 100%;
    min-height: 75vh;
    border-radius: 0;
    box-shadow: unset;
  }

  #profile-header {
    background: #f7f7f7;
    justify-content: center;
    padding: 3% 0 3% 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.16), 0 2px 2px rgba(0, 0, 0, 0.23);
  }

  #profile-header > h3 {
    margin: 0;
    margin-left: 0;
  }

  .attemptFailed > h6 {
    font-size: 10px;
    color: #f30;
  }

  #phone-number > h3 {
    font-size: 14px;
  }

  #phone-container > h3 {
    font-size: 12px;
  }

  #profile-header > h3 {
    font-size: 16px;
  }

  #name-container > h3,
  #email-container > h3 {
    font-size: 13px;
  }

  #save-changes-container,
  #delete-user-container {
    width: 33%;
  }

  #save-changes-button,
  #delete-user-button {
    font-size: 11px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #success-message,
  #failure-message {
    margin: 0;
    margin-left: 3%;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.5px;
  }

  #delete-user-modal,
  #deleted-user-modal,
  #not-deleted-user-modal {
    left: 5%;
    top: 15%;
  }

  input,
  #phone-input {
    font-size: 12px;
    padding-right: 10px;
  }
}

@media (max-width: 340px) {
  input,
  #phone-input {
    font-size: 12px;
  }

  #name-container > h3,
  #email-container > h3 {
    font-size: 11px;
  }

  #phone-container > h3 {
    font-size: 11px;
    /* padding-right: 10px; */
  }
}

@media (max-width: 300px) {
  input,
  #phone-input {
    font-size: 10px;
  }

  #name-container > h3,
  #email-container > h3 {
    font-size: 11px;
  }

  #phone-container > h3 {
    font-size: 10px;
    padding-right: 10px;
  }
}
</style>
