import { render, staticRenderFns } from "./ProfileScreen.vue?vue&type=template&id=d19acbb6&scoped=true&"
import script from "./ProfileScreen.vue?vue&type=script&lang=js&"
export * from "./ProfileScreen.vue?vue&type=script&lang=js&"
import style0 from "./ProfileScreen.vue?vue&type=style&index=0&id=d19acbb6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d19acbb6",
  null
  
)

export default component.exports