<template>
  <div v-if="deletedUser == undefined" id="delete-user-modal">
    <div id="delete-user-header">
      <h4 id="delete-user-title">{{ labels.profile.delete.areYouSure[language] }}</h4>
      <h4 id="delete-user-subtitle">{{ labels.profile.delete.noGoingBack[language] }}</h4>
    </div>
    <div id="sad-image">
      <img id="svg" src="../assets/delete-account.svg" />
    </div>
    <div id="buttons">
      <Button @click="onClose" id="stay-button">{{ labels.profile.delete.illStay[language] }}</Button>
      <Button
        @click="onDelete"
        id="delete-button"
      >{{ labels.profile.delete.deleteAnyway[language] }}</Button>
    </div>
  </div>
  <div v-else-if="deletedUser === true" id="deleted-user-modal">
    <div id="deleted-user-header">
      <h4 id="deleted-user-title">{{ labels.profile.delete.deletedSuccessfully[language] }}</h4>
      <h4 id="deleted-user-subtitle">{{ labels.profile.delete.thankYou[language] }}</h4>
    </div>
    <div id="sad-image">
      <img id="leave-svg" src="../assets/delete-account.svg" />
    </div>
    <div id="button">
      <Button
        @click="backAfterDelete"
        id="stay-button"
      >{{ labels.profile.delete.untilNextTime[language] }}</Button>
    </div>
  </div>
  <div v-else-if="deletedUser === false" id="not-deleted-user-modal">
    <div id="not-deleted-user-header">
      <h4 id="not-deleted-user-title">{{ labels.profile.delete.somethingWentWrong[language] }}</h4>
      <h4 id="not-deleted-user-subtitle">{{ labels.profile.delete.destiny[language] }}</h4>
    </div>
    <div id="sad-image">
      <img id="error-svg" src="../assets/something-went-wrong.svg" />
    </div>
    <div id="button">
      <Button @click="onClose" id="stay-button">{{ labels.profile.delete.backToProfile[language] }}</Button>
    </div>
  </div>
</template>

<script>
import Button from "./Button"
export default {
  name: "DeleteUserModal",
  components: {
    Button
  },
  methods: {
    onClose: function () {
      this.$emit("close");
    },

    onDelete: function () {
      this.$emit("delete");
    },

    backAfterDelete: function () {
      this.$router.push({ name: "search" })
    },
  },
  computed: {
    deletedUser() {
      return this.$store.getters.getDeleteUserSuccess;
    },

    language() {
      return this.$store.getters.getLanguage;
    }
  },
};
</script>

<style scoped>
#delete-user-modal,
#deleted-user-modal,
#not-deleted-user-modal {
  width: 55%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 16px;
  background: #f7f7f7;
  z-index: 202;
}

#delete-user-header,
#deleted-user-header,
#not-deleted-user-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#delete-user-title,
#deleted-user-title,
#not-deleted-user-title {
  margin: 0;
  margin-bottom: 1%;
  width: 90%;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1px;
}

#delete-user-subtitle,
#deleted-user-subtitle,
#not-deleted-user-subtitle {
  margin: 0;
  width: 60%;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  color: #f30;
  letter-spacing: 1px;
}

#deleted-user-subtitle,
#not-deleted-user-subtitle {
  color: #198b4a;
  color: #f30;
}

#buttons,
#button {
  display: flex;
  flex-direction: column;
  width: 70%;
}

#stay-button {
  border: none;
  /* width: 100%; */
  margin-bottom: 3%;
  background: linear-gradient(62deg, #198b4a, #04da6f);
  color: white;
  font-size: 14px;
  font-weight: 600;
}

#delete-button {
  border: none;
  width: 100%;
  background: transparent;
  color: #f30;
  border: 1px solid #f30;
  font-size: 14px;
  font-weight: 600;
  /* height: 40px; */
  transition-duration: 0.35s;
}

#delete-button:hover {
  background: #f30;
  color: white;
  /* border: none; */
}

#sad-image {
  display: flex;
  align-items: center;
  justify-content: center;
}

#svg {
  width: 18vw;
}

#leave-svg {
  width: 18vw;
}

#error-svg {
  width: 20vw;
}

@media (max-width: 800px) {
  #delete-user-modal,
  #deleted-user-modal,
  #not-deleted-user-modal {
    left: 5%;
    width: 90%;
    height: 70%;
    /* top: 15%; */
  }

  #delete-user-title,
  #deleted-user-title,
  #not-deleted-user-title {
    width: 90%;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 1px;
    margin-bottom: 5%;
  }

  #delete-user-subtitle,
  #deleted-user-subtitle,
  #not-deleted-user-subtitle {
    width: 90%;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    color: #f30;
    letter-spacing: 1px;
  }

  #buttons,
  #button {
    width: 80%;
  }

  #stay-button {
    margin-bottom: 6%;
    font-size: 14px;
    height: 40px;
  }

  #delete-button {
    font-size: 12px;
    height: 40px;
  }

  #svg {
    width: 60vw;
    max-height: 27vh;
  }

  #leave-svg {
    width: 60vw;
    max-height: 27vh;
  }

  #error-svg {
    width: 70vw;
    max-height: 27vh;
  }
}
</style>